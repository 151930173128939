import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import ArrowLeft from "@assets/calendar/arrowLeft.svg";
import ArrowRight from "@assets/calendar/arrowRight.svg";
import Calendar from "@assets/calendar/calendar.svg";
import Xcircle from "@assets/calendar/x-circle.svg";
import styled from "styled-components";
import { theme } from "@styles/themes";
import { useTranslation } from "react-i18next";
import { useCalendar } from "@context/CalendarContext";
import { useAuth } from "@context/AuthContext";

interface DateRangePickerProps {
  locale?: string;
}

const LabelText = styled.label`
  width: auto;
  font-size: 10px;
  font-weight: 400;
  color: ${theme.colors.white.normal};
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 16px;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  pointer-events: none;
  background-color: ${theme.colors.grey.dark};
  padding: 0 8px;
  border-radius: 4px;
`;

const CustomInput = forwardRef<HTMLInputElement, any>(
  ({ value, onClick, label, cleanData }, ref) => {
    return (
      <div className="custom-input-wrapper">
       {value && <LabelText>{label}</LabelText>} 
        <input
          onClick={onClick}
          ref={ref}
          value={value || label}
          readOnly
          className="date-range-picker__input"
        />
        <img 
        onClick={(event) => {
          event.stopPropagation()
          if(value){
            cleanData(null);
          }else{
            onClick();
          }
        }} 
        src={value ?  Xcircle : Calendar} 
        alt="calendar icon" 
        className="calendar-icon" 
        />
      </div>
    );
  }
);

const DateRangePicker: React.FC<DateRangePickerProps> = () => {
  const { t } = useTranslation();
  const {startDate, setStartDate, endDate, setEndDate } = useCalendar();
  const { accountSetting } = useAuth();

  const handleStartDateChange = (date: Date | null) => {
    if (endDate && date && date > endDate) {
      return;
    }
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    if (startDate && date && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  const getFormatDate = accountSetting?.dateFormat === 'DD/MM/YYYY' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';

  const language = {
    'EN': 'en-US',
    'ES': 'es-ES'
  }

  const locale = language[accountSetting?.language];

  return (
    <div
      className="date-range-picker"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        gap: "14px",
      }}
    >
      <div>
        <DatePicker
          id="start-date"
          selected={startDate}
          onChange={handleStartDateChange}
          startDate={startDate}
          dateFormat={getFormatDate} 
          popperPlacement="bottom-start"
          portalId="root"
          className="custom-datepicker"
          maxDate={endDate || undefined}
          customInput={<CustomInput label={t("calendar.startDate")} cleanData={setStartDate} />}
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => {
            const shortMonth = date
              .toLocaleString(locale ?? "en-US", { month: "short" })
              .charAt(0)
              .toUpperCase() + date.toLocaleString(locale ?? "en-US", { month: "short" }).slice(1);
            const year = date.getFullYear();
            return (
              <div className="custom-calendar-header">
                <h4 className="text-custom-header">
                  {shortMonth} {year}
                </h4>
                <div className="container-arrows">
                  <img
                    src={ArrowLeft}
                    style={{
                      maxWidth: "12px",
                      maxHeight: "12px",
                      cursor: "pointer",
                    }}
                    alt="arrow left"
                    onClick={decreaseMonth}
                  />
                  <img
                    src={ArrowRight}
                    style={{
                      maxWidth: "12px",
                      maxHeight: "12px",
                      cursor: "pointer",
                    }}
                    alt="arrow right"
                    onClick={increaseMonth}
                  />
                </div>
              </div>
            );
          }}
          calendarClassName="custom-calendar"
        />
      </div>
      <div>
        <DatePicker
          id="end-date"
          selected={endDate}
          onChange={handleEndDateChange}
          endDate={endDate}
          dateFormat={getFormatDate} 
          popperPlacement="bottom-end"
          portalId="root"
          minDate={startDate || undefined}
          customInput={<CustomInput label={t("calendar.endDate")} cleanData={setEndDate} />}
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => {
            const shortMonth = date
              .toLocaleString(locale ?? "en-US", { month: "short" })
              .charAt(0)
              .toUpperCase() + date.toLocaleString(locale ?? "en-US", { month: "short" }).slice(1);
            const year = date.getFullYear();
            return (
              <div className="custom-calendar-header">
                <h4 className="text-custom-header">
                  {shortMonth} {year}
                </h4>
                <div className="container-arrows">
                  <img
                    src={ArrowLeft}
                    style={{
                      maxWidth: "12px",
                      maxHeight: "12px",
                      cursor: "pointer",
                    }}
                    alt="arrow left"
                    onClick={decreaseMonth}
                  />
                  <img
                    src={ArrowRight}
                    style={{
                      maxWidth: "12px",
                      maxHeight: "12px",
                      cursor: "pointer",
                    }}
                    alt="arrow right"
                    onClick={increaseMonth}
                  />
                </div>
              </div>
            );
          }}
          calendarClassName="custom-calendar"
        />
      </div>
    </div>
  );
};

export default DateRangePicker;