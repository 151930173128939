import React, { useEffect } from "react";

import CopyBox from "./components/copyBox";
import { usePayment } from "@context/PaymentContext";
import { paymentMethods } from "@utils/payments";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import { useAuth } from "@context/AuthContext";

const BankTransferDetail = () => {
  const { isPaymentSuccess, paymentMethod, amount, bankTransferResponse, currency} = usePayment();
  const { t } = useTranslation();

  const { convertToAppCurrency } = useAuth();
  
  useEffect(() => {
    if (isPaymentSuccess && paymentMethods.bankTransfer === paymentMethod) {
      toast.success(t("success.bankTransfer"));
    }
  }, [isPaymentSuccess]);

  const dataBankDetail = {
    'eur': {
      concept: bankTransferResponse?.data?.concept,
      titular: "DRACAL SL",
      bic: "TRWIBEB1XXX",
      iban: "BE90 9052 4611 0932",
      address: `Wise Rue du Trone 100, 3rd floor Brussels 1050`,
      amount: convertToAppCurrency(amount)
    },
    'usd': {
      concept: bankTransferResponse?.data?.concept,
      titular: "DRACAL SL",
      bic: "TRWIUS35XXX",
      accountNumber: "786055124389573",
      routeNumber: `084009519`,
      address: `Wise US Inc, 30 W. 26th Street, Sixth Floor, New York, NY, 10010, United States`,
      amount: convertToAppCurrency(amount)
    }
  }

  const bankAccount = dataBankDetail[currency];

  return (
    isPaymentSuccess &&
    <div>
      <CopyBox title="payments.accountTitular" code={bankAccount.titular} />
      <CopyBox title="payments.accountNumber" code={bankAccount.accountNumber} />
      <CopyBox title="payments.routeNumber" code={bankAccount.routeNumber} />
      <CopyBox title="payments.iban" code={bankAccount.iban} />
      <CopyBox title="payments.bic" code={bankAccount.bic} />
      <CopyBox
        title="payments.namebankaddress"
        code={bankAccount.address}
      />
      <CopyBox title="payments.AmountToSend" code={bankAccount.amount} />
      <CopyBox title="payments.useConceptBelow" code={bankAccount.concept} />

      <div style={{ padding: "0 16px", marginTop: "12px" }}>
        <Heading
          fontSize="14px"
          title="payments.conceptDisclaimer"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
      </div>
    </div>
  );
};

export default BankTransferDetail;
