import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import Chip from "@components/chip";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { useUpdateBookies } from "@api/useUpdateBookies";
import { UpdateBookiesParams } from "@api/types";
import { useGetProfiles } from "@api/useGetProfiles";
import { useAuth } from "@context/AuthContext";
import { toast } from "react-toastify";
import { ReactComponent as PlayIcon } from "@assets/icons/play.svg";
import { ReactComponent as Pause } from "@assets/icons/pause.svg";
import { ReactComponent as Edit } from "@assets/icons/edit.svg";
import { ReactComponent as Record } from "@assets/icons/record.svg";
import { useTranslation } from "react-i18next";

const LeftActions = ({ setPausePlay, navigate, onUpdateBookie, bookies }) => (
  <TrailingActions>
    <SwipeAction destructive={false} onClick={() => {}}>
      <div className="d-flex align-items-center">
        {bookies?.isRunning ? (
          <Pause
            style={{
              width: "24px",
              height: "24px",
              marginLeft: "30px",
              color: theme.colors.white.normal,
            }}
            onClick={() => {
              setPausePlay(true);
              onUpdateBookie();
            }}
          />
        ) : (
          <PlayIcon
            style={{
              width: "24px",
              height: "24px",
              marginLeft: "30px",
              color: theme.colors.white.normal,
            }}
            onClick={() => {
              setPausePlay(false);
              onUpdateBookie();
            }}
          />
        )}

        <Edit
          style={{
            width: "24px",
            height: "24px",
            marginLeft: "30px",
            color: theme.colors.white.normal,
          }}
          onClick={() => {
            navigate(`/profile/bookie/add/edit`, {
              state: {
                bookie: bookies?.bookie,
                isEnabled: bookies?.isEnabled,
                accountId: bookies?.accountId,
              },
            });
          }}
        />

        <Record
          style={{
            width: "24px",
            height: "24px",
            marginLeft: "30px",
            color: theme.colors.white.normal,
          }}
          onClick={() => {
            navigate("/profile/bookie/record", {
              state: { bookie: bookies },
            });
          }}
        />
      </div>
    </SwipeAction>
  </TrailingActions>
);

const MobileAccordian = ({ index, bookies }: any) => {
  const { t } = useTranslation();
  const { refetch } = useGetProfiles({});
  const { convertToAppCurrency } = useAuth();

  const [activeKey, setActiveKey] = React.useState<any>(null);

  const navigate = useNavigate();

  const handleToggle = (key: any) => {
    setActiveKey(activeKey === key ? null : key);
  };
  const [pausePlay, setPausePlay] = React.useState<boolean>(false);

  const { mutate: updateBookie } = useUpdateBookies({
    onSuccess: (data) => {
      console.log("Bookie updated successfully:", data);
      refetch();
    },
    onError: (error) => {
      console.error("Failed to update bookie:", error);
      toast.error("Failed to update bookie");
    },
  });

  const handleUpdateBookie = () => {
    const updateData: UpdateBookiesParams = {
      bookieId: bookies?.accountId,
      // isEnabled: !bookies?.isEnabled,
      bookie: bookies?.bookie,
      isRunning: !bookies?.isRunning,
    };

    updateBookie(updateData);
    if (bookies?.isRunning) {
      toast.success(t("success.stopUser"));
    } else {
      toast.success(t("success.bookieResumed"));
    }
  };

  const handleArchieveBookie = (accountId) => {
    const updateData: UpdateBookiesParams = {
      bookieId: accountId,
      isEnabled: true,
      bookie: bookies?.bookie,
    };
    updateBookie(updateData, {
      onSuccess: () => {
        toast.success(t("success.bookieResumed"));
      },
    });
  };

  return (
    <>
      {bookies?.isEnabled === false ? (
        <div
          style={{
            backgroundColor: theme.colors.grey.normal,
            borderRadius: index !== activeKey ? "8px" : "8px 8px 0 0",
            padding: "14px 16px",
            marginBottom: "16px",
            width: "100%",
          }}
          onClick={() => handleToggle(index)}
        >
          <Row className="align-items-center">
            <Col lg={5} md={5} sm={5} xs={5}>
              <div className="d-flex align-items-center">
                <div>
                <img
                  src={require(`../../../assets/logosBookies/${bookies?.bookie.toLowerCase()}.png`)}
                  alt="logo bookie"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50px",
                    marginRight: "10px",
                  }}
                />
                </div>
                <div
                 style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width:'120px'
                }}
                >
                  <Heading
                    title={bookies.username}
                    fontSize="14px"
                    fontWeight="400"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                  }}
                    color={theme.colors.white.dark}
                  />
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right", padding:"0px" }}>
              <Heading
                title={convertToAppCurrency(bookies?.profits || 0)}
                fontSize="14px"
                fontWeight="700"
                color={theme.colors.white.normal}
              />
            </Col>
            <Col lg={3} md={3} sm={3} xs={3} style={{ textAlign: "right" }}>
              <Icon
                style={{
                  fontSize: "24px",
                  color: theme.colors.white.normal,
                }}
                onClick={() => handleArchieveBookie(bookies?.accountId)}
                icon="mage:reload"
              />
            </Col>
          </Row>
        </div>
      ) : (
        <SwipeableList
          fullSwipe={false}
          type={Type.IOS}
          style={{ marginBottom: "16px" }}
        >
          <SwipeableListItem
            trailingActions={
              <LeftActions
                setPausePlay={setPausePlay}
                navigate={navigate}
                onUpdateBookie={handleUpdateBookie}
                bookies={bookies}
              />
            }
          >
            <Accordion activeKey={activeKey} style={{ width: "100%" }}>
              <div
                style={{
                  backgroundColor: theme.colors.grey.dark,
                  borderRadius: index !== activeKey ? "8px" : "8px 8px 0 0",
                  padding: "10px 16px",
                  cursor: "pointer",
                  width: "100%",
                }}
                onClick={() => handleToggle(index)}
              >
                <Row className="align-items-center">
                  <Col lg={5} md={5} sm={5} xs={5}>
                    <div className="d-flex align-items-center">
                    <div>
                      <img
                        src={require(`../../../assets/logosBookies/${bookies?.bookie.toLowerCase()}.png`)}
                        alt="logo bookie"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50px",
                          marginRight: "10px",
                        }}
                      />
                      </div>
                      <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width:'120px'
                      }}
                      >
                        <Heading
                          title={bookies?.username}
                          fontSize="14px"
                          fontWeight="400"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                          color={theme.colors.white.normal}
                        />
                        <div style={{ width: "max-content" }}>
                          <Chip
                            title={
                              bookies?.error
                                ? t("profile.error")
                                : !bookies?.slot
                                ?  t("profile.waiting") 
                                : bookies?.isRunning
                                ? t("profile.active")
                                : t("profile.pause")
                            }
                            color={
                              bookies?.error
                                ? theme.colors.red.normal
                                : !bookies?.slot
                                ? theme.colors.gray
                                : bookies?.isRunning
                                ? theme.colors.green.normal
                                : theme.colors.yellow.normal
                            }
                            bgColor={theme.colors.grey.dark}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right", padding:'0px' }}>
                    <Heading
                      title={convertToAppCurrency(bookies?.profits || 0)}
                      fontSize="14px"
                      fontWeight="700"
                      color={theme.colors.white.normal}
                    />
                  </Col>
                  <Col lg={3} md={3} sm={3} xs={3} style={{ textAlign: "right" }}>
                    <Icon
                      icon={
                        activeKey === index ? "fe:arrow-up" : "fe:arrow-down"
                      }
                      width="18"
                      style={{ color: theme.colors.white.normal }}
                    />
                  </Col>
                </Row>
              </div>

              <Accordion.Collapse
                eventKey={index}
                style={{
                  backgroundColor: theme.colors.grey.dark,
                  borderRadius: index !== activeKey ? "8px" : "0px 0px 8px 8px",
                }}
              >
                <div className="mb-3" style={{ paddingLeft: "48px" }}>
                  <div>
                    <Row
                      className="align-items-center"
                      style={{ padding: "0 16px" }}
                    >
                      <Col lg={9} md={9} sm={9} xs={9}>
                        <div className="d-flex align-items-center">
                          <Heading
                            style={{
                              width: "max-content",
                              minWidth: "140px",
                              whiteSpace: "nowrap",
                            }}
                            title="profile.bank"
                            fontSize="14px"
                            fontWeight="400"
                            className="me-1"
                            color={theme.colors.white.normal}
                          />
                          <Heading
                            title={convertToAppCurrency(
                              bookies?.last_bank || 0
                            )}
                            fontSize="14px"
                            style={{
                              width: "100%",
                              textAlign: "left",
                              whiteSpace: "nowrap",
                            }}
                            fontWeight="400"
                            className="me-1"
                            color={theme.colors.white.normal}
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <Heading
                            title="profile.totalBank"
                            style={{
                              minWidth: "140px",
                              width: "max-content",
                              whiteSpace: "nowrap",
                            }}
                            fontSize="14px"
                            fontWeight="400"
                            className="me-1"
                            color={theme.colors.white.normal}
                          />
                          <Heading
                            title={convertToAppCurrency(
                              bookies?.total_bank || 0
                            )}
                            fontSize="14px"
                            style={{
                              width: "100%",
                              textAlign: "left",
                              whiteSpace: "nowrap",
                            }}
                            fontWeight="400"
                            className="me-1"
                            color={theme.colors.white.normal}
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <Heading
                            title="profile.openBets"
                            style={{
                              width: "max-content",
                              minWidth: "140px",
                              whiteSpace: "nowrap",
                            }}
                            fontSize="14px"
                            fontWeight="400"
                            className="me-1"
                            color={theme.colors.white.normal}
                          />
                          <Heading
                            title={bookies?.currentOB_number}
                            fontSize="14px"
                            fontWeight="400"
                            style={{
                              width: "100%",
                              textAlign: "left",
                              whiteSpace: "nowrap",
                            }}
                            className="me-1"
                            color={theme.colors.white.normal}
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <Heading
                            title="profile.creditLimit"
                            style={{
                              width: "max-content",
                              minWidth: "140px",
                              whiteSpace: "nowrap",
                            }}
                            fontSize="14px"
                            fontWeight="400"
                            className="me-1"
                            color={theme.colors.white.normal}
                          />
                          <Heading
                            title={`${bookies?.creditLimit}`}
                            fontSize="14px"
                            fontWeight="400"
                            style={{
                              width: "100%",
                              textAlign: "left",
                              whiteSpace: "nowrap",
                            }}
                            className="me-1"
                            color={theme.colors.white.normal}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div style={{ padding: "6px 16px 16px 16px" }}>
                      {bookies?.error && (
                        <Heading
                          title={bookies?.errorMessage}
                          fontSize="12px"
                          fontWeight="400"
                          style={{ lineHeight: "18px" }}
                          color={theme.colors.red.normal}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </SwipeableListItem>
        </SwipeableList>
      )}
    </>
  );
};

export default MobileAccordian;
