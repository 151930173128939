import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { theme } from "@styles/themes";
// Define ButtonProps interface to include a style prop
interface ButtonProps {
  outline?: boolean;
  useThemeColor?: keyof DefaultTheme["colors"];
  textColor?: keyof DefaultTheme["colors"]; // Prop for custom text color
  size?: "sm" | "md" | "lg"; // Prop for button size
  startIcon?: React.ReactNode; // Icon to be displayed at the start of the button
  endIcon?: React.ReactNode; // Icon to be displayed at the end of the button
  title?: any; // Add title prop here
  style?: CSSProperties; // Custom inline styles
  onClick?: any;
  type?: any;
  className?: any;
  isLoading?: boolean;
  disabled?: boolean;
}

// Define size variations
const sizeStyles = {
  sm: "4px 8px",
  md: "6px 12px",
  lg: "8px 16px",
};

// Create a styled button that adapts based on props
const LoadingOverlap = styled.div<ButtonProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ outline, theme, useThemeColor, disabled }) =>
    outline
      ? "transparent"
      : disabled === true
      ? theme.colors.main.darker
      : theme.colors[useThemeColor || "primary"]};
  border: 2px solid
    ${({ theme, useThemeColor, disabled }) =>
      disabled === true
        ? theme.colors.main.darker
        : theme.colors[useThemeColor || "primary"]};
  color: ${({ outline, theme, useThemeColor, disabled }) =>
    outline
      ? theme.colors[useThemeColor || "primary"]
      : disabled === true
      ? theme.colors.main.dark
      : "black"};
  text-align: center;
  text-decoration: none;
  display: inline-flex; // Using flexbox for aligning content and icons
  align-items: center;
  justify-content: center;
  font-size: 16px;
  &:hover {
    background-color: ${({ outline, theme, useThemeColor, disabled }) =>
      outline
        ? theme.colors[useThemeColor || "primary"]
        : disabled === true
        ? "none"
        : theme.colors.main.normalHover};
    cursor: ${({ disabled }) =>
      disabled === true ? "not-allowed" : "pointer"};
    color: ${({ outline, disabled }) =>
      outline
        ? "white"
        : disabled === true
        ? "none"
        : "black"}; // Change color on hover
    .startIcon,
    .endIcon {
      color: ${theme.colors.white.normal}; // Adjust the color you want on hover
    }
  }
`;

const StyledButton = styled.button<ButtonProps>`
  position: relative;
  background-color: ${({ outline, theme, useThemeColor, disabled }) =>
    outline
      ? "transparent"
      : disabled === true
      ? theme.colors.main.darker
      : theme.colors[useThemeColor || "primary"]};
  border: 2px solid
    ${({ theme, useThemeColor, disabled }) =>
      disabled === true
        ? theme.colors.main.darker
        : theme.colors[useThemeColor || "primary"]};
  color: ${({ outline, theme, useThemeColor, disabled }) =>
    outline
      ? theme.colors[useThemeColor || "primary"]
      : disabled === true
      ? theme.colors.main.dark
      : "black"};
  padding: ${({ size }) =>
    sizeStyles[size || "md"]}; // Set padding based on size
  text-align: center;
  text-decoration: none;
  display: inline-flex; // Using flexbox for aligning content and icons
  align-items: center;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${({ outline, theme, useThemeColor, disabled }) =>
      outline
        ? theme.colors.grey.darkActive
        : disabled === true
        ? "none"
        : theme.colors.main.normalHover};
        border: 2px solid
    ${({ theme, useThemeColor, disabled }) =>
      disabled === true
        ? theme.colors.main.darker
        : theme.colors.main.normalHover};
    cursor: ${({ disabled }) =>
      disabled === true ? "not-allowed" : "pointer"};
    color: ${({ outline, disabled }) =>
      outline
        ? theme.colors.main.normalHover
        : disabled === true
        ? "none"
        : "black"}; // Change color on hover
    .startIcon,
    .endIcon {
       color: ${({ outline, disabled }) =>
      outline
        ? theme.colors.main.normalHover
        : disabled === true
        ? "none"
        : "black"}; // Change color on hover // Adjust the color you want on hover
    }
  }

  // Styling for icons to add space between icon and text
  .icon {
    display: flex;
    align-items: center;
  }

  .startIcon {
    margin-right: 8px; // Space between start icon and text
  }

  .endIcon {
    margin-left: 8px; // Space between text and end icon
  }
`;

export const Button: React.FC<ButtonProps> = ({
  outline = false,
  useThemeColor = "primary",
  textColor,
  size = "md",
  startIcon,
  className,
  endIcon,
  title,
  type,
  style, // Include style here
  onClick,
  isLoading = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  
  return (
    <StyledButton
      outline={outline}
      useThemeColor={useThemeColor}
      textColor={textColor}
      type={type}
      size={size}
      disabled={disabled}
      className={className}
      style={style}
      onClick={onClick}
    >
      {startIcon && <span className="icon startIcon">{startIcon}</span>}

      {isLoading && (
        <LoadingOverlap
          outline={outline}
          useThemeColor={useThemeColor}
          textColor={textColor}
          type={type}
          size={size}
          disabled={disabled}
          className={className}
          style={style} // Apply custom styles
          onClick={onClick}
        >
          <Spinner animation="border" size="sm" />
        </LoadingOverlap>
      )}

      {typeof title === "string" ? t(title) : title}

      {endIcon && <span className="icon endIcon">{endIcon}</span>}
    </StyledButton>
  );
};
