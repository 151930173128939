import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import Header from "./header";
import SideBar from "./sideBar";
import BottomBar from "../mobile/bottomBar";
import BillingDetailHeader from "./billingDetailHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import { TabContext } from "@context/TabContext";
import ProfileHeader from "./profileHeader";
import ProfileBookieHeader from "./profileBookieHeader";
import NotificationHeader from "./notificationHeader";

const PrimaryLayout = ({ children }: any) => {
  const location = useLocation();

  const pathname = location?.pathname;
  const { setLeftPosition } = useContext(TabContext);
  const leftColumnRef = React.useRef(null);

  const isDesktop = window.matchMedia("(min-width: 768px)").matches;

  React.useEffect(() => {
    const updateLeftPosition = () => {
      if (leftColumnRef.current) {
        const width = leftColumnRef.current.offsetWidth + 12;
        setLeftPosition(width);
      }
    };

    updateLeftPosition();

    window.addEventListener("resize", updateLeftPosition);

    return () => {
      window.removeEventListener("resize", updateLeftPosition);
    };
  }, [leftColumnRef]);

  const isHeaderVisible =
  !(
    pathname === "/payments/billingdetails" ||
    pathname === "/payments/banktransfer" ||
    pathname === "/profile/mydata" ||
    pathname === "/profile/billingdetails" ||
    pathname === "/profile/billingdetails/add" ||
    pathname === "/payments/billingdetails" ||
    pathname === "/payments/billingdetails/add" ||
    pathname === "/profile/setting" ||
    pathname === "/profile/contactus" ||
    pathname === "/profile/bookie" ||
    pathname === "/profile/bookie/add/new" ||
    pathname === "/profile/bookie/add/edit" ||
    pathname === "/profile/bookie/record" ||
    pathname === "/profile/bookie/archive" ||
    pathname === "/notification"
  );

  return (
    <div>
      <div className="d-block d-md-none">
       {isHeaderVisible && <Header />}

        {pathname === "/notification" && <NotificationHeader />}
        {pathname === "/payments/billingdetails" && <BillingDetailHeader />}
        {pathname?.includes("/profile/bookie") && <ProfileBookieHeader />}
        {(pathname === "/profile/billingdetails" ||
          pathname === "/profile/mydata" ||
          pathname === "/profile/billingdetails" ||
          pathname === "/profile/billingdetails/add" ||
          pathname === "/profile/setting" ||
          pathname === "/profile/contactus") && <ProfileHeader />}
      </div>

      <Container style={{padding:'0px'}} fluid>
        <Row className="g-0">
          <Col
            ref={leftColumnRef}
            md={3}
            className="d-none d-md-block"
            style={{ position: "relative" }}
          >
            <SideBar />
          </Col>

          <Col 
          md={9}
          sm={12}
          style={{
            paddingTop: pathname === "/payments/banktransfer" ? '0px' : '60px', 
            height: '100%',
            backgroundColor: '#1A1A1A',
         }}>
            {children}
          </Col>
        </Row>

        <div className="d-block d-md-none">
          {!(
            pathname === "/payments/billingdetails" ||
            pathname === "/payments/banktransfer" ||
            pathname === "/profile/mydata" ||
            pathname === "/profile/billingdetails" ||
            pathname === "/profile/billingdetails/add" ||
            pathname === "/profile/setting" ||
            pathname === "/profile/bookie/add/new" ||
            pathname === "/profile/bookie/add/edit" ||
            pathname === "/profile/contactus" ||
            pathname === "/profile/bookie/record" ||
            pathname === "/profile/bookie/archive" ||
            pathname === "/notification"
          ) && <BottomBar />}
        </div>
      </Container>
    </div>
  );
};

export default PrimaryLayout;
