import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useContext, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import MobileAccordian from "./mobileAccordian";
import { useGetProfiles } from "@api/useGetProfiles";
import { TabContext } from "@context/TabContext";
import SkeletonLoader from "@components/loader/skeleton";
import { Button } from "@components/button/button";
import { useNavigate } from "react-router-dom";
import { usePayment } from "@context/PaymentContext";
import AddCreditsModal from "@components/modal/AddCreditsModal";

const MobileAccordianCard = () => {
  const { data, isLoading } = useGetProfiles({});
  const navigate = useNavigate();
  const { profileIdSelected } = useContext(TabContext);
  const bookiesLength = data?.profiles?.find(
    (elem) => elem.profileId === profileIdSelected
  )?.bookies.length;

  const internalRef = useRef<HTMLDivElement>(null);
  const { availableCredits } = usePayment();
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <>
      {bookiesLength <= 0 ? (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "82vh", padding:'0px 16px' }}
        >
          {openModal && <AddCreditsModal 
          open={openModal} 
          onClose={()=> setOpenModal(false)} 
          actionBtnTitle={'profile.creditsNeededAction'}
          onActionClick={()=> navigate('/payments')}
          />}
          <Heading
            title="profile.thereAreNoBookies"
            fontSize="20px"
            fontWeight="700"
            style={{ margin: "12px 0 24px 0", textAlign: "center" }}
            color={theme.colors.white.normal}
          />
          <Button
            title="profile.addBookie"
            onClick={() => {
                if(availableCredits < 1){
                  setOpenModal(true)
                }else{
                  navigate("/profile/bookie/add/new")
                }
            }}
            style={{
              fontSize: "17px",
              padding: "8px 24px",
              fontWeight: "700",
              borderRadius: "8px",
            }}
          />
        </div>
      ) : (
        <Row className="mt-3 g-0" style={{ marginBottom: "80px", padding:'0px 16px', overflowY: "auto" }} 
        ref={internalRef}>
          <Col lg={5} md={5} sm={5} xs={5}>
            <Heading
              title="profile.bookie"
              fontSize="14px"
              fontWeight="400"
              className="mb-2"
              style={{ marginLeft: "12px" }}
              color={theme.colors.white.dark}
            />
          </Col>
          <Col lg={4} md={4} sm={4} xs={4} style={{ textAlign: "right" }}>
            <Heading
              title="profile.profit"
              fontSize="14px"
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}/>
          {isLoading ? (
            <Col lg={12} md={12} sm={12} xs={12}>
              <SkeletonLoader height="70px" />
            </Col>
          ) : (
            data?.profiles
              ?.filter((elem) => elem.profileId === profileIdSelected)
              .map((profile) =>
                profile.bookies
                  ?.sort((a, b) =>
                    a.isEnabled === b.isEnabled ? 0 : a.isEnabled ? -1 : 1
                  ) 
                  .map((bookie, index) => (
                    <Col lg={12} md={12} sm={12} xs={12} key={index}>
                      <MobileAccordian index={index} bookies={bookie} />
                    </Col>
                  ))
              )
          )}
        </Row>
      )}
    </>
  );
};

export default MobileAccordianCard;
