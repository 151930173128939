import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DateRangePicker from "@components/calendar";
import PaymentDetailInfo from "@components/paymentInfoCard";
import Heading from "@components/font/heading";
import SkeletonLoader from "@components/loader/skeleton";
import { useGetPaymentSummary } from "@api/useGetPaymentSummary";
import { paymentMethodText } from "@utils/payments";
import { theme } from "@styles/themes";
import { PaymentsRecord } from "@api/types";
import { useCalendar } from "@context/CalendarContext";

const StyledSeeMore = styled.h1`
  font-size: 14px;
  font-weight: 400;
  font-family: Open Sans, sans-serif;
  color: ${theme.colors.white.normal};
  text-decoration: underline;
`;

const ResponsiveDiv = styled.div`
  padding-right: 32px;
  position: relative;

  @media (max-width: 1200px) and (min-width: 740px) {
    padding-right: 0;
  }
  @media (max-width: 739px) {
    padding-right: 0;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;


const ContainerHeader = styled.div`
@media (min-width: 768px) {
  position: sticky;
  top: 60px;
  background: #1A1A1A;
  width: 100%;
  padding: 24px 0 12px 0;
  z-index: 2;
}
@media (min-width: 1200px) {
  position: sticky;
  top: 85px;
  background: #1A1A1A;
  width: 100%;
  padding: 0px 0 12px 0;
  z-index: 2;
}
`
const ContainerHeaderTable = styled.div`
@media (min-width: 768px) {
  position: sticky;
  top: 130px;
  background: #1A1A1A;
  width: 100%;
  padding: 24px 0 6px 0;
  z-index: 2;
}
@media (min-width: 1200px) {
  position: sticky;
  top: 135px;
  background: #1A1A1A;
  width: 100%;
  padding: 12px 0 6px 0;
  z-index: 2;
}
`

const Space = styled.div`
@media (min-width: 1200px) {
  position: fixed;
  top: 60px;
  width: 100%;
  background: #1A1A1A;
  height: 25px;
  z-index: 2;
}
`

const PaymentRecord = () => {
  // variables
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { data, isLoading } = useGetPaymentSummary();
  const [visiblePayments, setVisiblePayments] = useState<PaymentsRecord[]>([]);
  const [initialData, setInitialData] = useState<PaymentsRecord[]>([]);
   
  const { control, watch } = useForm({
    defaultValues: {
      search: "",
    },
  });
  const watchSearch = watch("search");

  const {startDate, endDate, resetData } = useCalendar();

  // effects
  useEffect(() => {
    let list = isMobile
      ? data?.paymentsRecord?.slice(0, 3)
      : data?.paymentsRecord;

    if (watchSearch) {
      list = list?.filter((item) =>
        item?.paymentAmount?.toString().includes(watchSearch)
      );
    }

    setVisiblePayments(list);
    setInitialData(list);
  }, [watchSearch, data]);


  useEffect(() => {
    if (startDate && endDate) {
      const startTimestamp = startDate.getTime() / 1000;
      const endTimestamp = endDate.getTime() / 1000;
  
      const filtered = initialData.filter(
        (item) =>
          item.uploadTimestamp >= startTimestamp &&
          item.uploadTimestamp <= endTimestamp
      );
  
      setVisiblePayments(filtered);
    }
    else {
      setVisiblePayments(initialData);
    }
  }, [startDate, endDate]);


  useEffect(() => {
    return () => {
      resetData();
    };
    }, []); 

  return (
    !isLoading &&
    <ResponsiveDiv>
      <Space />
      <ContainerHeader>
      <StyledContainer>
        <div className="d-none d-md-block">
          <Heading
            fontSize="20px"
            title={t("payments.paymentRecord")}
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <div className="d-block d-md-none">
          <Heading
            fontSize="17px"
            title={t("payments.paymentRecord")}
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{marginTop:'12px'}}
          />
        </div>
        {(initialData?.length > 0) && (
          <div className="d-none d-md-block" style={{ width: "330px" }}>
              <DateRangePicker />
          </div>
        )}
      </StyledContainer>
      </ContainerHeader>
      {visiblePayments?.length <= 0 ? (
        <>
        <Row className="d-block d-md-none" style={{padding:"24px 0px"}}>
            <DateRangePicker />
          </Row>
          <Heading
            fontSize="17px"
            title={t("payments.thereIsNoPayments")}
            fontWeight="400"
            color={theme.colors.white.dark}
          />
          </>
      ) : (
        <>
        <ContainerHeaderTable>
        <Row className="g-0" style={{padding:"0 24px"}}>
          <Col xl={3} lg={3} md={3} sm={3} className="d-none d-md-block">
            <Heading
              fontSize="14px"
              title={t("payments.credits")}
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={3} className="d-none d-md-block">
            <Heading
              fontSize="14px"
              title={t("payments.paymentMethod")}
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} className="d-none d-md-block">
            <Heading
              fontSize="14px"
              title={t("payments.date")}
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={3} className="d-none d-md-block">
            <Heading
              fontSize="14px"
              title={t("payments.state")}
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col xl={1} lg={1} md={1} sm={1} className="d-none d-md-block"></Col>
        </Row>
        </ContainerHeaderTable>
         <div>
         {visiblePayments ? (
           visiblePayments?.map((elem, index) => (
             <PaymentDetailInfo
               key={index}
               amount={elem.paymentAmount}
               paymentMethod={paymentMethodText[elem.method]}
               timestamp={elem.uploadTimestamp}
               status={elem.status}
               invoiceUrl={elem.invoiceUrl}
             />
           ))
         ) : (
           <SkeletonLoader />
         )}
         {isMobile && visiblePayments && data?.paymentsRecord?.length > 3 && (
           <div className="my-3 mb-5">
             <StyledSeeMore onClick={() => navigate("records")}>
               {t("actions.seeMore")}
             </StyledSeeMore>
           </div>
         )}
       </div>
       </>
      )}
    </ResponsiveDiv>
  );
};

export default PaymentRecord;
