import React from "react";
import AlertModal from "./AlertModal";

interface AddCreditsModalProps {
  open: boolean;
  onClose(): void;
  closeBtnTitle?: string;
  actionBtnTitle?: string;
  onActionClick(): void;
}

const AddCreditsModal: React.FC<AddCreditsModalProps> = ({
  open,
  onClose,
  closeBtnTitle,
  actionBtnTitle,
  onActionClick,
}) => {
  return (
    <AlertModal
      open={open}
      onClose={onClose}
      title={'profile.creditsNeededTitle'}
      description={'profile.creditsNeededDescription'}
      closeBtnTitle={closeBtnTitle}
      actionBtnTitle={actionBtnTitle}
      onActionClick={onActionClick}
    />
  );
};

export default AddCreditsModal;
