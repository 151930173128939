import { AddAddressRequest } from "@api/useAddAddress";
import { useBankTransfer } from "@api/useBankTransfer";
import {
  PaymentResponse,
  useCreditCardPayment,
} from "@api/useCreditCardPayment";
import {
  AppCurrency,
  convertToCurrencyAmount,
  currencyPaymentCode,
} from "@utils/currency";
import {
  paymentMethods,
  paymentMethodText,
  PaymentMethodType,
} from "@utils/payments";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuth } from "./AuthContext";
import { useGetPaymentSummary } from "@api/useGetPaymentSummary";

type BillingAddressType = AddAddressRequest & { billingId?: string };
type ChallengeStatus = "success" | "error" | null;
interface DefaultValue {
  setToken: Dispatch<SetStateAction<string>>;
  token: string | null;
  setOrderId: Dispatch<SetStateAction<string>>;
  orderId: string | null;
  setAmount: Dispatch<SetStateAction<number>>;
  amount: number | null;
  setIsAcceptedTerms: Dispatch<SetStateAction<boolean>>;
  isAcceptedTerms: boolean;
  setCurrency: Dispatch<SetStateAction<string>>;
  cardHolderName: string | null;
  setCardHolderName: Dispatch<SetStateAction<string>>;
  challengeStatus: ChallengeStatus;
  setChallengeStatus: Dispatch<SetStateAction<ChallengeStatus>>;
  currency: string | null;
  setBillingDetails: Dispatch<SetStateAction<BillingAddressType>>;
  billingDetails: BillingAddressType | null;
  startPayment(): void;
  isPaymentSuccess: boolean;
  isPaymentFailed: boolean;
  resetPayment(): void;
  convertToCredit(amount: number): number;
  convertToPaymentCurrency(amount: number): string;
  credit: number;
  redsysResponse: PaymentResponse;
  paymentMethod: keyof typeof paymentMethodText;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethodType>>;
  isLoading: boolean;
  resetRedsysResponse: () => void;
  availableCredits: number;
  setAvailableCredits: Dispatch<SetStateAction<number>>;
  bankTransferResponse: any;
}

const defaultValue: DefaultValue = {
  setToken: () => null,
  token: null,
  setOrderId: () => null,
  orderId: null,
  setBillingDetails: () => null,
  billingDetails: null,
  setAmount: () => null,
  amount: null,
  setIsAcceptedTerms: () => false,
  isAcceptedTerms: false,
  isPaymentFailed: null,
  resetPayment: () => null,
  currency: null,
  setCurrency: () => null,
  paymentMethod: null,
  setPaymentMethod: () => null,
  startPayment: () => null,
  convertToCredit: () => null,
  convertToPaymentCurrency: () => null,
  isPaymentSuccess: false,
  credit: 0,
  redsysResponse: null,
  cardHolderName: null,
  setCardHolderName: () => null,
  isLoading: false,
  challengeStatus: null,
  setChallengeStatus: () => null,
  resetRedsysResponse: () => null,
  availableCredits: 0,
  setAvailableCredits: () => 0,
  bankTransferResponse: null,
};

const PaymentContext = createContext(defaultValue);

export const PaymentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [orderId, setOrderId] = useState<string>(null);
  const [token, setToken] = useState<string>(null);
  const [currency, setCurrency] = useState<AppCurrency>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>(null);
  const [amount, setAmount] = useState<number>(null);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState<boolean>(false);
  const [billingDetails, setBillingDetails] =
    useState<BillingAddressType>(null);
  const [cardHolderName, setCardHolderName] = useState<string>(null);
  const [challengeStatus, setChallengeStatus] = useState<ChallengeStatus>(null);
  const [availableCredits, setAvailableCredits] = useState<number>(0);

  const { accountSetting } = useAuth();

  const { data: paymentSummary } = useGetPaymentSummary();

  const {
    mutate: submitCreditCard,
    isLoading,
    isSuccess: isCreditCardPaySuccess,
    isError: isCreditCardPayFailed,
    data: redsysResponse,
    reset: resetCreditCard,
  } = useCreditCardPayment();

  const {
    mutate: submitBankTransfer,
    isLoading: isBankTransferLoading,
    isSuccess: isBankTransferSuccess,
    isError: isBankTransferFailed,
    reset: resetBankTransfer,
    data: bankTransferResponse,
  } = useBankTransfer();

  const convertToCredit = (amount: number) => {
    const multiplier = parseFloat(
      currency
        ? (1 / paymentSummary?.creditsFee[currency.toUpperCase()]).toFixed(2)
        : "0"
    ).toFixed(2);

    return parseFloat((parseFloat(multiplier) * amount).toFixed(2));
  };

  const credit = useMemo(() => {
    return convertToCredit(amount);
  }, [currency, amount]);

  useEffect(() => {
    setCurrency(accountSetting?.currency);
  }, [accountSetting]);

  const startPayment = () => {
    switch (paymentMethod) {
      case paymentMethods.creditCard:
        submitCreditCard({
          paymentAmount: amount,
          currency: currencyPaymentCode[currency],
          idOperation: token,
          orderNumber: orderId,
          cardholderName: cardHolderName,
          isAcceptedTerms,
          billingDetails,
        });
        break;

      case paymentMethods.bankTransfer:
        submitBankTransfer({
          paymentAmount: amount,
          currency: currencyPaymentCode[currency],
          isAcceptedTerms,
          billingDetails,
        });
        break;

      default:
        return;
    }
  };

  useEffect(()=>{
    if(paymentSummary){
      setAvailableCredits(paymentSummary.credits);
    }
  },[paymentSummary]);

  const resetPayment = () => {
    setToken(null);
    setOrderId(null);
    setCardHolderName(null);
    setChallengeStatus(null);
    setPaymentMethod(null);

    switch (paymentMethod) {
      case paymentMethods.creditCard:
        resetCreditCard();
        break;

      case paymentMethods.bankTransfer:
        resetBankTransfer();
        break;

      default:
        return;
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        orderId,
        setOrderId,
        token,
        setToken,
        amount,
        setAmount,
        billingDetails,
        setBillingDetails,
        currency,
        setCurrency,
        startPayment,
        isPaymentSuccess: isCreditCardPaySuccess || isBankTransferSuccess,
        credit,
        convertToCredit,
        convertToPaymentCurrency: (amount) =>
          convertToCurrencyAmount(amount, currency, accountSetting?.numberFormat, accountSetting?.language),
        isPaymentFailed: isCreditCardPayFailed || isBankTransferFailed,
        resetPayment,
        redsysResponse,
        setPaymentMethod,
        paymentMethod,
        cardHolderName,
        setCardHolderName,
        isLoading: isLoading || isBankTransferLoading,
        challengeStatus,
        setChallengeStatus,
        resetRedsysResponse: resetCreditCard,
        isAcceptedTerms,
        setIsAcceptedTerms,
        availableCredits,
        setAvailableCredits,
        bankTransferResponse,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = () => useContext(PaymentContext);
