import React from "react";
import styled from "styled-components";
import Heading from "@components/font/heading";
import checkedcircle from "@assets/icons/checkcircle.svg";
import { theme } from "@styles/themes";
import { Button } from "@components/button/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 10px;
  height: 90vh;
  text-align: center;
`;

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
`;

const SuccessPageSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <StyledImage src={checkedcircle} alt="check icon" />
      <Heading
        title={t("payments.success")}
        fontSize="17px"
        fontWeight="400"
        color={theme.colors.white.normal}
      />
      <Button
        onClick={()=> navigate("/profile")}
        title={t("payments.investInBookies")}
        style={{ fontWeight: "700", fontSize: "14px", borderRadius: "8px" }}
      />
    </Container>
  );
};

export default SuccessPageSection;
