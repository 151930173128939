import eu from "@assets/flags/eu.svg";
import us from "@assets/flags/us.svg";

export type AppCurrency = keyof typeof currencies;

export const currencies = {
  eur: {
    value: "eur",
    label: "Euro",
    flagSrc: eu,
  },
  usd: {
    value: "usd",
    label: "Dollar",
    flagSrc: us,
  },
};

export const currenyMultipliers = {
  eur: 2.5,
  usd: 2,
};

export const currencyPaymentCode = {
  eur: 978,
  usd: 840,
};

export const currencyToLocaleMap = {
  eur: "de-DE", // Germany
  usd: "en-US", // United States
};
export const convertToCurrencyAmount = (
  amount: number,
  currency: keyof typeof currencyToLocaleMap,
  customFormat?: string,
  language?: string,
  unShowSymbol: boolean = false
) => {
  if (!currency) {
    return amount ? amount.toString() : null;
  }

  const formattedAmount = (() => {
    if (customFormat) {
      const [thousandsSeparator, decimalSeparator] = customFormat.includes(",")
        ? [".", ","]
        : [",", "."];
      const [integerPart, decimalPart] = amount
        .toFixed(2)
        .toString()
        .split(".");

      const formattedInteger = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        thousandsSeparator
      );

      if (Math.abs(amount) < 10) {
        return integerPart;
      }

      return `${formattedInteger}${decimalSeparator}${decimalPart}`;
    }

    const parts = new Intl.NumberFormat(currencyToLocaleMap[currency], {
      style: "currency",
      currency: currency,
      currencyDisplay: "narrowSymbol",
    })
      .formatToParts(amount)
      .filter((part) => part.type === "integer" || part.type === "decimal");

    if (Math.abs(amount) < 10) {
      return parts.filter((part) => part.type === "integer").map((part) => part.value).join("");
    }

    return parts.map((part) => part.value).join("");
  })();

  const cleanedFormattedAmount = formattedAmount.replace(/\.$/, "");

  const currencySymbol = new Intl.NumberFormat(currencyToLocaleMap[currency], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
    .formatToParts(0)
    .find((part) => part.type === "currency")?.value;

  if (language === "ES") {
    return unShowSymbol
      ? cleanedFormattedAmount
      : `${cleanedFormattedAmount} ${currencySymbol}`;
  }

  if (language === "EN") {
    return unShowSymbol
      ? cleanedFormattedAmount
      : `${currencySymbol}${cleanedFormattedAmount}`;
  }

  return `${cleanedFormattedAmount}`;
};
