import React from "react";
import { Col, Row } from "react-bootstrap";
import PaymentRecord from "./paymentRecord";
import GetCreditCard from "./getCreditCard";
import styled from "styled-components";
import Head from "../profileSection/myDataSection/head";

const ResponsiveDiv = styled.div`
  padding: 24px;

  @media (max-width: 1200px) and (min-width: 740px) {
    padding-right: 20px;
  }
  @media (max-width: 739px) {
    padding: 12px 16px 60px 16px;
  }
`;

const PaymentPageSection = () => {
  return (
    <ResponsiveDiv>
     <Head title='profile.credits' backBtn={false}/>
      
      <Row className="g-0">
        <Col
          sm={12}
          xs={12}
          md={12}
          xl={8}
          lg={12}
          className="order-xl-1 order-lg-2 order-md-2 order-sm-2 order-2"
        >
          <PaymentRecord />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          xl={4}
          lg={12}
          className="order-xl-2 order-lg-1 order-md-1 order-sm-1 order-1"
        >
          <GetCreditCard />
        </Col>
      </Row>
    </ResponsiveDiv>
  );
};

export default PaymentPageSection;
