import { AddAddressRequest } from "@api/useAddAddress";
import Heading from "@components/font/heading";
import { RadioButton } from "@components/form/radioButton";
import { SearchSelectBox } from "@components/form/searchSelect";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { appCountries } from "@utils/countries";
import { City, ICountry } from "country-state-city";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "@components/button/button";
import { useNavigate } from "react-router-dom";
import { useGetAddresses } from "@api/useGetAddress";
import CopyModal from "@components/modal/CopyModal";
import { useDeleteAddress } from "@api/useDeleteAddress";
import { useUpdateAddress } from "@api/useUpdateAddress";
import BillEmpty from "@assets/billingEmpty.svg";
import { regexPatterns } from "@utils/regex";
import { CheckboxField } from "@components/CheckboxField";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth } from "@context/AuthContext";

// Styled components
const StyledContainer = styled.div`
  border: 1px solid ${theme.colors.white.dark};
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
  max-width: 600px;
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const StyledCol = styled(Col)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  min-width: 3em;
  width: auto;
  margin-top: 16px;
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledMobileButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  min-width: 3em;
  width: 100%;
  margin-top: 16px;
  margin: 0;

  @media (min-width: 768px) {
    display: none;
  }
`;

const StyledMobileDiv = styled.div`
  display: flex;
  column-gap: 16px;
  background-color: ${theme.colors.grey.darker};
  position: fixed;
  width: 100%;
  padding: 14px 16px;
  border-top: 0.5px solid ${theme.colors.white.dark};
  bottom: 0;
  left: 0;
  @media (min-width: 768px) {
    display: none;
  }
`;

const StyledAddDetailBtn = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  min-width: 4em;
  width: auto;
  margin-top: 16px;
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const CollapsibleSection = styled(motion.div)`
  overflow: hidden;
`;

type AddressForm = AddAddressRequest & {
  radio_select: string;
  phonePrefix: any;
};

const BillingAddressForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedBillingId, setSelectedBillingId] = useState<string | null>(
    null
  );

  const { accountSetting } = useAuth();

  const mainOptions = appCountries(accountSetting?.language)?.map((country: ICountry) => ({
    value: country.isoCode,
    label: country.name,
  }));

  const { mutate, isLoading } = useUpdateAddress({
    onSuccess: () => {
      toast.success(t("success.billingSaved"));
    },
  });
  const { mutate: deleteAddress } = useDeleteAddress({
    onSuccess: () => {
      setOpenDelete(false);
      toast.success(t("success.billingDeleted"));
    },
  });

  const { data } = useGetAddresses();
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<AddressForm>();

  const watchCountry = watch("country");
  const watchCity = watch("city");

  // City and phone code options
  const city = City.getCitiesOfCountry(getValues("country"));
  const mainCity = city?.map((citys: any) => ({
    value: citys.stateCode,
    label: citys.name,
  }));

  useEffect(() => {
    if (selectedBillingId) {
      const selectedAddress: any = data?.find(
        (elem) => elem.billingId === selectedBillingId
      );
      if (selectedAddress) {
        setValue("isDefault", selectedAddress?.isDefault);

        if (selectedAddress?.isCompany) {
          setValue("address", selectedAddress?.address);
          setValue("addressExtra", selectedAddress?.addressExtra);
          setValue("city", selectedAddress?.city);
          setValue("country", selectedAddress?.country);
          setValue("zipCode", selectedAddress?.zipCode);
          setValue("vat", selectedAddress?.VAT);
          setValue("companyName", selectedAddress?.companyName);
          setValue("isCompany", selectedAddress?.isCompany);
        } else {
          setValue("name", selectedAddress?.name);
          setValue("surname", selectedAddress?.surname);
          setValue("address", selectedAddress?.address);
          setValue("addressExtra", selectedAddress?.addressExtra);
          setValue("city", selectedAddress?.city);
          setValue("country", selectedAddress?.country);
          setValue("zipCode", selectedAddress?.zipCode);
          setValue("nif", selectedAddress?.personId);
          setValue("isCompany", selectedAddress?.isCompany);
        }
      }
    }
  }, [selectedBillingId, data, setValue]);

  const onSubmit = (formData: AddressForm) => {
    const formdataUpdated = {
      billingId: selectedBillingId,
      country: formData?.country || "",
      city: formData?.city || "",
      address: formData?.address || "",
      addressExtra: formData?.addressExtra || "",
      zipCode: formData?.zipCode || "",
      isCompany: formData?.isCompany,
      isDefault: formData?.isDefault,
      companyName: formData?.companyName,
      VAT: formData?.vat,
      ...(formData?.isCompany === false && {
        name: formData?.name || "",
        surname: formData?.surname || "",
        personId: formData?.nif,
      }),
    };

    mutate(formdataUpdated);
  };

  return (
    <div style={{ marginBottom: "80px" }}>
      {data?.length <= 0 && !isLoading && (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <img
            src={BillEmpty}
            style={{ maxWidth: "182px", minHeight: "182px" }}
          />
          <Heading
            title="profile.nothingToSee"
            fontSize="24px"
            fontWeight="700"
            style={{ padding: "24px 0" }}
            color={theme.colors.white.normal}
          />
          <Button
            title="profile.addBillingDetails"
            onClick={() => navigate("add")}
            style={{
              fontSize: "17px",
              fontWeight: "700",
              borderRadius: "8px",
            }}
          />
        </div>
      )}

      {data?.map((elem: any, index) => {
        const isSelected = selectedBillingId === elem?.billingId;

        return (
          <StyledContainer key={elem?.billingId}>
            <div
              className="d-flex align-items-start"
              onClick={() => {
                setSelectedBillingId(elem?.billingId);
              }}
            >
              <RadioWrapper>
                <Controller
                  name="radio_select"
                  control={control}
                  defaultValue={elem?.isDefault}
                  render={({ field, value }: any) => (
                    <RadioButton
                      name="billingAddress"
                      value={elem?.billingId}
                      checked={isSelected}
                    />
                  )}
                />
              </RadioWrapper>
              <div
                className="mx-3"
                style={{ overflowWrap: "anywhere", marginBottom: "8px" }}
              >
                <Heading
                  fontSize="14px"
                  title={elem?.isCompany ? elem?.companyName : elem?.name}
                  fontWeight="700"
                  color={theme.colors.white.normal}
                />
                <Heading
                  fontSize="14px"
                  title={`${elem?.address}, ${elem?.addressExtra}, ${elem?.city} (${elem?.country})`}
                  fontWeight="400"
                  color={theme.colors.white.dark}
                />
              </div>
            </div>

            <AnimatePresence initial={false}>
              {isSelected && (
                <CollapsibleSection
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{
                    duration: 0.3,
                    ease: "easeInOut",
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="g-3">
                      {elem?.isCompany === true ? (
                        <Col md={12} sm={12} xs={12}>
                          <TextField
                            floatingLabel
                            control={control}
                            name="companyName"
                            bgColor={theme.colors.grey.dark}
                            size="sm"
                            defaultValue={elem?.companyName}
                            placeholder={t("profile.companyName")}
                            errors={errors.companyName}
                            rules={{ required: t("errors.required") }}
                          />
                        </Col>
                      ) : (
                        <>
                          <Col md={12} sm={12} xs={12}>
                            <TextField
                              floatingLabel
                              control={control}
                              name="name"
                              bgColor={theme.colors.grey.dark}
                              size="sm"
                              defaultValue={elem?.name}
                              placeholder={t("profile.name")}
                              errors={errors.name}
                              rules={{
                                required: t("errors.required"),
                                pattern: {
                                  value: regexPatterns?.regOnlyChar,
                                  message: t("errors.namePattern"),
                                },
                              }}
                            />
                          </Col>

                          <Col md={12} sm={12} xs={12}>
                            <TextField
                              floatingLabel
                              control={control}
                              name="surname"
                              defaultValue={elem?.surname}
                              bgColor={theme.colors.grey.dark}
                              size="sm"
                              placeholder={t("profile.surname")}
                              errors={errors.surname}
                              rules={{
                                required: t("errors.required"),
                                pattern: {
                                  value: regexPatterns?.regOnlyChar,
                                  message: t("errors.namePattern"),
                                },
                              }}
                            />
                          </Col>
                        </>
                      )}

                      <Col md={6} sm={6} xs={6}>
                        <SearchSelectBox
                          bgColor={theme.colors.grey.dark}
                          size="sm"
                          selectValue="isoCode"
                          selectLabel="name"
                          defaultLabel={elem?.country}
                          options={mainOptions}
                          placeholder={t("profile.country")}
                          control={control}
                          name="country"
                          setValue={setValue}
                          errors={errors.country}
                          value={watchCountry}
                          rules={{ required: t("errors.required") }}
                        />
                      </Col>

                      <Col md={6} sm={6} xs={6}>
                        <SearchSelectBox
                          bgColor={theme.colors.grey.dark}
                          size="sm"
                          options={mainCity}
                          selectValue="stateCode"
                          defaultValue={elem?.city}
                          selectLabel="name"
                          placeholder={t("profile.city")}
                          control={control}
                          name="city"
                          setValue={setValue}
                          errors={errors.city}
                          value={watchCity}
                          rules={{ required: t("errors.required") }}
                        />
                      </Col>

                      <Col md={12} sm={12} xs={12}>
                        <TextField
                          floatingLabel
                          control={control}
                          name="address"
                          defaultValue={elem?.address}
                          bgColor={theme.colors.grey.dark}
                          size="sm"
                          placeholder={t("profile.address")}
                          errors={errors.address}
                          rules={{ required: t("errors.required") }}
                        />
                      </Col>

                      <Col md={12} sm={12} xs={12}>
                        <TextField
                          floatingLabel
                          control={control}
                          name="addressExtra"
                          defaultValue={elem?.addressExtra}
                          bgColor={theme.colors.grey.dark}
                          size="sm"
                          placeholder={t("profile.additionalAddress")}
                          rules={{ required: false }}
                        />
                      </Col>

                      <Col md={3} sm={3} xs={5}>
                        <TextField
                          floatingLabel
                          control={control}
                          name="zipCode"
                          bgColor={theme.colors.grey.dark}
                          size="sm"
                          defaultValue={elem?.zipCode}
                          placeholder={t("profile.zipCode")}
                          errors={errors.zipCode}
                          rules={{
                            required: t("errors.required"),
                            pattern: {
                              value: regexPatterns?.zipCodeRegex,
                              message: t("errors.zipCode"),
                            },
                          }}
                        />
                      </Col>

                      <Col md={9} sm={9} xs={7}>
                        {elem.isCompany === true ? (
                          <TextField
                            floatingLabel
                            control={control}
                            name={"vat"}
                            bgColor={theme.colors.grey.dark}
                            size="sm"
                            defaultValue={elem?.VAT}
                            placeholder={"Tax ID*"}
                            errors={errors.vat}
                            rules={{ required: t("errors.required") }}
                          />
                        ) : (
                          <TextField
                            floatingLabel
                            control={control}
                            name={"nif"}
                            bgColor={theme.colors.grey.dark}
                            size="sm"
                            defaultValue={elem?.personId}
                            placeholder={"TIN*"}
                            errors={errors.nif}
                            rules={{ required: t("errors.required") }}
                          />
                        )}
                      </Col>

                      <Col md={12}>
                        <CheckboxField
                          control={control}
                          name="isDefault"
                          label={t("payments.defaultBillingDetails")}
                        />
                      </Col>
                      <StyledCol md={12}>
                        <StyledButton
                          title="actions.delete"
                          outline
                          style={{ marginRight: "20px" }}
                          type="button"
                          onClick={() => {
                            if (selectedBillingId) {
                              setOpenDelete(true);
                            }
                          }}
                        />
                        <StyledButton title="profile.saveChanges" />
                      </StyledCol>
                    </Row>
                  </form>
                </CollapsibleSection>
              )}
            </AnimatePresence>
          </StyledContainer>
        );
      })}
      {data?.length > 0 && (
        <StyledMobileDiv>
          <StyledMobileButton
            title="profile.saveChanges"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          />
          <StyledMobileButton
            title="actions.delete"
            outline
            type="button"
            onClick={() => {
              if (selectedBillingId) {
                setOpenDelete(true);
              }
            }}
          />
        </StyledMobileDiv>
      )}
      {data?.length > 0 && (
        <div>
          <StyledAddDetailBtn
            title="profile.addBillingDetails"
            onClick={() => navigate("add")}
          />
        </div>
      )}
      <CopyModal
        open={openDelete}
        setOpen={setOpenDelete}
        heading="profile.deleteBillngDetails"
        mainContentStyle={{ padding: "0" }}
        mainContent={
          <>
            <Heading
              title="profile.actionCantUndone"
              fontSize="14px"
              fontWeight="400"
              style={{ padding: "24px 0" }}
              color={theme.colors.white.normal}
            />
            <div style={{ textAlign: "right" }}>
              <Button
                outline={true}
                title="addEditUser.cancelButton"
                onClick={() => setOpenDelete(false)}
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  borderRadius: "8px",
                  marginRight: "14px",
                }}
              />
              <Button
                title="actions.delete"
                onClick={() => deleteAddress({ id: selectedBillingId })}
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  borderRadius: "8px",
                }}
              />
            </div>
          </>
        }
      />
    </div>
  );
};

export default BillingAddressForm;
