import React, { useContext, useState } from "react";
import MobileProfileCard from "./mobileProfileCard";
import DesktopProfileHead from "./desktopProfileHead";
import DesktopProfileCard from "./desktopProfileCard";
import CopyModal from "@components/modal/CopyModal";
import SettingModal from "./settingModal";
import AddProfileModal from "./addProfileModal";
import EditProfileModal from "./editProfileModal";
import ArchieveProfileModal from "./archieveProfileModal";
import BookieAddForm from "./profileBookie/bookieAdd";
import DrawerRecord from "./drawerRecord";
import { useDeactivateProfile } from "@api/useDeactivateProfile";
import { useGetProfiles } from "@api/useGetProfiles";
import { TabContext } from "@context/TabContext";
import { useUpdateBookies } from "@api/useUpdateBookies";
import { UpdateBookiesParams } from "@api/types";
import BookieEditForm from "./profileBookie/bookieEdit";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArchieveMobileBookieModal from "./profileBookie/bookieArchieve/mobileArchieveModal";
import { Bookie } from "@api/useUpdateBookiesMultiple";
import { Loading } from "@components/loader/Loading";
import { toast } from "react-toastify";

const tempBookie: Bookie = {
  bookie: "",
  isEnabled: false,
  accountId: "",
};

const ProfileSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [settingOpen, setSettingOpen] = React.useState<boolean>(false);
  const [addProfileOpen, setAddProfileOpen] = React.useState<boolean>(false);
  const [editProfileOpen, setEditProfileOpen] = React.useState<boolean>(false);
  const [addBookieOpen, setAddBookieOpen] = React.useState<boolean>(false);
  const [editBookieOpen, setEditBookieOpen] = React.useState<boolean>(false);
  const [pausePlay, setPausePlay] = React.useState<boolean>(false);
  const [archieveBookieOpen, setArchieveBookieOpen] =
    React.useState<boolean>(false);
  const [archieveBookieData, setArchieveBookieData] = React.useState<any>("");
  const [drawerRecordOpen, setDrawerRecordOpen] =
    React.useState<boolean>(false);
  const [archieveProfileOpen, setArchieveProfileOpen] =
    React.useState<boolean>(false);
  const { refetch, isLoading, isRefetching } = useGetProfiles({});
  const [bookie, setBookie] = useState<Bookie>(tempBookie);

  const { mutate } = useDeactivateProfile({
    onSuccess: () => {
      refetch();
    },
  });

  const { mutate: updateBookie, isLoading: archieveLoading } = useUpdateBookies(
    {
      onSuccess: (data) => {
        console.log("Bookie updated successfully:", data);
        refetch();
      },
      onError: (error) => {
        console.error("Failed to update bookie:", error);
        toast.error(t("errors.unknowBookieError"));
      },
    }
  );

  const { profileIdSelected } = useContext(TabContext);
  const handleArchieveDeactivateProfile = () => {
    if (archieveProfileOpen) {
      handleArchieveBtn();
    } else {
      setArchieveProfileOpen(true);
    }
  };

  const handleArchieveActivateProfile = async (profileId: string) => {
    try {
      await mutate({ params: { profileId: profileId }, isActive: true });
      await toast.success(t("success.runningProfile"));
    } catch (error) {
      console.error("Error activating profile:", error);
    }
  };
  
  const handleArchieveBtn = async () => {
    try {
      await mutate({
        params: { profileId: profileIdSelected },
        isActive: false,
      });
      setArchieveProfileOpen(false);
      await toast.success(t("success.archieveProfile"));
    } catch (error) {
      console.error("Error deactivating profile:", error);
    }
  };
  const handleDropdownMenuClick = (id: number) => {
    if (id === 1) {
      setAddBookieOpen(true);
    } else if (id === 2) {
      setEditProfileOpen(true);
    } else if (id === 3) {
      setArchieveProfileOpen(true);
    }
  };

  const handleArchieveBookie = (bookies) => {
    const updateData: UpdateBookiesParams = {
      bookieId: bookies?.accountId,
      isEnabled: !bookies?.isEnabled,
      bookie: bookies?.bookie,
    };
    updateBookie(updateData, {
      onSuccess: () => {
        setArchieveBookieOpen(false);
        if (!bookies?.isEnabled) {
          toast.success(t("success.bookieResumed"));
        } else {
          toast.success(t("success.bookieArchived"));
        }
      },
    });
  };
  
  const handleBookieDropdownMenuClick = (id: number, bookies: Bookie) => {
    if (id === 1) {
      handleUpdateBookie(bookies);
    } else if (id === 2) {
      setBookie(bookies);
      setDrawerRecordOpen(true);
    } else if (id === 3) {
      setEditBookieOpen(true);
      navigate(`/profile`, {
        state: {
          bookie: bookies?.bookie,
          isEnabled: bookies?.isEnabled,
          accountId: bookies?.accountId,
          id: "edit",
        },
      });
    } else if (id === 4) {
      if (!archieveBookieOpen) {
        setArchieveBookieOpen(true);
        setArchieveBookieData(bookies);
      }
    }
  };

  const handleUpdateBookie = (bookies: any) => {
    const updateData: UpdateBookiesParams = {
      bookieId: bookies?.accountId,
      // isEnabled: !bookies?.isEnabled,
      bookie: bookies?.bookie,
      isRunning: !bookies?.isRunning,
    };

    updateBookie(updateData);
    // if (bookies?.isRunning) {
    //   toast.success(t("success.runningUser"));
    // } else {
    //   toast.success(t("success.stopUser"));
    // }
  };

  return (
    <div>
      <div className="d-block d-sm-none">
        {isRefetching ? (
          <div
            style={{ height: "80vh" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading />
          </div>
        ) : (
          <MobileProfileCard
            setAddProfileOpen={setAddProfileOpen}
            setEditProfileOpen={setEditProfileOpen}
            setArchieveProfileOpen={setArchieveProfileOpen}
            handleArchieveDeactivateProfile={handleArchieveDeactivateProfile}
            handleArchieveActivateProfile={handleArchieveActivateProfile}
          />
        )}
      </div>
      <div
        className="d-none d-sm-block"
        style={{ margin: " 0px 24px 24px", paddingBottom: "24px", position: "relative" }}
      >
        <DesktopProfileHead setAddProfileOpen={setAddProfileOpen}/>
        <DesktopProfileCard
          setAddProfileOpen={setAddProfileOpen}
          settingOpen={settingOpen}
          setAddBookieOpen={setAddBookieOpen}
          setEditBookieOpen={setEditBookieOpen}
          setDrawerRecordOpen={setDrawerRecordOpen}
          handleDropdownMenuClick={handleDropdownMenuClick}
          handleArchieveDeactivateProfile={handleArchieveDeactivateProfile}
          handleArchieveActivateProfile={handleArchieveActivateProfile}
          handleBookieDropdownMenuClick={handleBookieDropdownMenuClick}
          setPausePlay={setPausePlay}
        />
      </div>
      <CopyModal
        open={settingOpen}
        setOpen={setSettingOpen}
        heading="profile.setting"
        mainContentStyle={{ padding: "0" }}
        mainContent={<SettingModal onClose={() => setSettingOpen(false)} />}
      />
      <CopyModal
        open={addProfileOpen}
        setOpen={setAddProfileOpen}
        heading="profile.addProfile"
        mainContentStyle={{ padding: "0" }}
        mainContent={
          <AddProfileModal
            setAddProfileOpen={setAddProfileOpen}
            setToastMessage={toast.success}
          />
        }
      />
      <CopyModal
        open={editProfileOpen}
        setOpen={setEditProfileOpen}
        heading="profile.editProfile"
        mainContentStyle={{ padding: "0" }}
        mainContent={
          <EditProfileModal
            setEditProfileOpen={setEditProfileOpen}
            setToastMessage={toast.success}
          />
        }
      />
      <CopyModal
        open={archieveProfileOpen}
        setOpen={setArchieveProfileOpen}
        heading="profile.archieveProfile"
        mainContentStyle={{ padding: "0" }}
        mainContent={
          <ArchieveProfileModal
            setArchieveProfileOpen={setArchieveProfileOpen}
            handleArchieveBtn={handleArchieveBtn}
          />
        }
      />
      <CopyModal
        open={addBookieOpen}
        setOpen={setAddBookieOpen}
        heading="profile.addBookie"
        mainContentStyle={{ padding: "0" }}
        mainContent={<BookieAddForm setAddBookieOpen={setAddBookieOpen} />}
      />
      <CopyModal
        open={editBookieOpen}
        setOpen={setEditBookieOpen}
        heading="profile.editBookie"
        mainContentStyle={{ padding: "0" }}
        mainContent={<BookieEditForm setEditBookieOpen={setEditBookieOpen} />}
      />
      <DrawerRecord
        setShow={setDrawerRecordOpen}
        show={drawerRecordOpen}
        bookie={bookie}
        filterType='bookies'
      />
      <CopyModal
        open={archieveBookieOpen}
        setOpen={setArchieveBookieOpen}
        heading="profile.archieveBookie"
        mainContentStyle={{ paddingTop: "16px" }}
        mainContent={
          <ArchieveMobileBookieModal
            handleArchieveBtn={() => handleArchieveBookie(archieveBookieData)}
            isLoading={archieveLoading}
            setArchieveBookieOpen={setArchieveBookieOpen}
          />
        }
      />
    </div>
  );
};

export default ProfileSection;
