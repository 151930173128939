import React from "react";
import BankTransferDetail from "../../section/billingDetails/bankTranferDetail";
import { useNavigate } from "react-router-dom";

import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import { Icon } from "@iconify/react";

const BankTransferPage = () => {
  const navigate = useNavigate();
  const handleCross = () => {
    navigate("/payments");
  };

  return (
    <div style={{paddingBottom:'24px'}}>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          borderBottomColor: theme.colors.grey.dark,
          padding: "16px  16px 14px 16px",
          height: "55px",
        }}
      >
        <Heading
          fontSize="20px"
          title="payments.bankTransfer"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
        <div className="d-flex align-items-center">
          <Icon
            onClick={handleCross}
            icon={"charm:cross"}
            width="24"
            height="24"
            color={theme.colors.white.normal}
          />
        </div>
      </div>
      <BankTransferDetail />
    </div>
  );
};

export default BankTransferPage;
