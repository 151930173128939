import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import { Col } from "react-bootstrap";
import AccordianProfile from "./accordianProfile";
import styled from "styled-components";
import { useGetProfiles } from "@api/useGetProfiles";
import SkeletonLoader from "@components/loader/skeleton";
import ProfileEmpty from "@assets/profileEmpty.svg";
import { useNavigate } from "react-router-dom";

const CustomDiv = styled.div`
  width: 14.4%;
  @media (max-width: 1200px) {
    width: 33.4%;
  }
`;
const CustomProfitDiv= styled.div`
  width: 14.4%;
  @media (max-width: 1200px) {
    width: 42%;
  }
`;
const CustomButtonDiv= styled.div`
  width: 15.2%;
  text-align:right;
  @media (max-width: 1200px) {
    width: 33%;
  }
`;
const CustomDivHide = styled.div`
  width: 14%;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const BodyDesktop = styled.div`
`;

const HeaderDesktop = styled.div`
@media (min-width: 1028px) {
top: 180px;
position: sticky;
padding: 24px 0px 6px 24px;
background-color: #1A1A1A;
z-index: 2;
width: 100%;
}
@media (max-width: 1127px) {
  top: 229px;
}
`;

const DesktopProfileCard = ({
  setAddProfileOpen,
  settingOpen,
  setEditBookieOpen,
  setAddBookieOpen,
  setDrawerRecordOpen,
  handleDropdownMenuClick,
  handleArchieveDeactivateProfile,
  handleArchieveActivateProfile,
  handleBookieDropdownMenuClick,
  setPausePlay,
}: any) => {
  const { data, isLoading } = useGetProfiles({});

  return (
    <BodyDesktop>
      {data?.profiles?.length <= 0 ? (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "580px" }}
        >
          <img
            src={ProfileEmpty}
            style={{ maxWidth: "292px", maxHeight: "292px" }}
            alt='icon'
          />
          <Heading
            title="profile.welcomeInbetia"
            fontSize="24px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
          <Heading
            title="profile.addingBookieProfile"
            fontSize="17px"
            fontWeight="400"
            style={{ margin: "12px 0 24px 0" }}
            color={theme.colors.white.normal}
          />
          <Button
            title="profile.addProfile"
            outline={true}
            onClick={() => setAddProfileOpen(true)}
            style={{
              fontSize: "18px",
              padding: "12px 24px",
              fontWeight: "500",
              borderRadius: "8px",
            }}
          />
        </div>
      ) : (
        <>
          <HeaderDesktop
            className="d-flex align-items-center"
          >
            <CustomDiv>
              <Heading
                title="profile.profile"
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.dark}
              />
            </CustomDiv>
            <CustomProfitDiv style={{ textAlign: "right" }}>
              <Heading
                title="profile.profit"
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.dark}
              />
            </CustomProfitDiv>
            <CustomDivHide style={{ textAlign: "right" }}>
              <Heading
                title="profile.state"
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.dark}
              />
            </CustomDivHide>
            <CustomDivHide style={{ textAlign: "right" }}>
              <Heading
                title="profile.bank"
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.dark}
              />
            </CustomDivHide>
            <CustomDivHide style={{ textAlign: "right" }}>
              <Heading
                title="profile.totalBank"
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.dark}
              />
            </CustomDivHide>
            <CustomDivHide style={{ textAlign: "right" }}>
              <Heading
                title="profile.openBets"
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.dark}
              />
            </CustomDivHide>
            <CustomButtonDiv>
              <Heading
                title="profile.actions"
                fontSize="14px"
                fontWeight="400"
                style={{textAlign:'right', paddingRight:'24px'}}
                color={theme.colors.white.dark}
              />
            </CustomButtonDiv>
          </HeaderDesktop>
          {isLoading ? (
            <Col lg={12} md={12} sm={12} xs={12} className="mt-2">
              <SkeletonLoader />
            </Col>
          ) : (
            data?.profiles?.map((elem: any, index: number) => (
              <AccordianProfile
                key={index}
                elem={elem}
                index={index}
                setEditBookieOpen={setEditBookieOpen}
                setAddBookieOpen={setAddBookieOpen}
                setDrawerRecordOpen={setDrawerRecordOpen}
                handleDropdownMenuClick={handleDropdownMenuClick}
                handleArchieveDeactivateProfile={
                  handleArchieveDeactivateProfile
                }
                handleArchieveActivateProfile={handleArchieveActivateProfile}
                handleBookieDropdownMenuClick={handleBookieDropdownMenuClick}
                setPausePlay={setPausePlay}
              />
            ))
          )}
        </>
      )}
    </BodyDesktop>
  );
};

export default DesktopProfileCard;
