import moment from "moment";


  
export const monthDays = {
    1: {
      value: "01",
      label: "January",
      days: Array.from({ length: 31 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    2: {
      value: "02",
      label: "February",
      days: Array.from({ length: 28 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    3: {
      value: "03",
      label: "March",
      days: Array.from({ length: 31 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    4: {
        value: "04",
      label: "April",
      days: Array.from({ length: 30 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    5: {
      value: "05",
      label: "May",
      days: Array.from({ length: 31 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    6: {
      value: "06",
      label: "June",
      days: Array.from({ length: 30 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    7: {
      value: "07",
      label: "July",
      days: Array.from({ length: 31 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    8: {
      value: "08",
      label: "August",
      days: Array.from({ length: 31 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    9: {
      value: "09",
      label: "September",
      days: Array.from({ length: 30 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    10: {
      value: "10",
      label: "October",
      days: Array.from({ length: 31 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    11: {
      value: "11",
      label: "November",
      days: Array.from({ length: 30 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
    12: {
      value: "12",
      label: "December",
      days: Array.from({ length: 31 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    },
  };
  
  
  const currentYear = moment().year(); // Get the current year
  const startYear = 1923; // Starting year
  
  export const years = Object.fromEntries(
    Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
      const year = currentYear - i; // Reverse the order
      return [year, { value: year.toString(), label: year.toString() }];
    })
  );

  export const hours = Object.fromEntries(
    Array.from({ length: 24 }, (_, i) => {
      const hour = i; // 0 to 23
      return [hour, { value: hour.toString().padStart(2, '0'), label: hour.toString().padStart(2, '0') }];
    })
  );
  export const minutes = Object.fromEntries(
    Array.from({ length: 61 }, (_, i) => {
      const hour = i;
      return [hour, { value: hour.toString().padStart(2, '0'), label: hour.toString().padStart(2, '0') }];
    })
  );
  
const nextYear = currentYear + 1;

export const closingYears = {
  [currentYear]: { value: currentYear.toString(), label: currentYear.toString() },
  [nextYear]: { value: nextYear.toString(), label: nextYear.toString() },
};