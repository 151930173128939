import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import Chip from "@components/chip";
import styled from "styled-components";
import { TabContext } from "@context/TabContext";
import { useAuth } from "@context/AuthContext";
import flags from "@assets/flags";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import { useUpdateBookies } from "@api/useUpdateBookies";
import { UpdateBookiesParams } from "@api/types";
import { useGetProfiles } from "@api/useGetProfiles";
import Badge from "@components/Badge";
import { Profile } from "@api/useGetProfiles";
import verticalDots from "@assets/icon/dots-vertical.svg";
import Reload from "@assets/icon/refresh.svg";
import IconButtonHover from "@components/button/Iconbutton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { usePayment } from "@context/PaymentContext";
import AddCreditsModal from "@components/modal/AddCreditsModal";
import { useNavigate } from "react-router-dom";

const HoverDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.colors.grey
      .darker}; // Change background color on hover
  }
`;

const dropDownMenu = [
  { title: "profile.pause", icon: "heroicons-outline:pause", id: 1 },
  { title: "profile.record", icon: "mage:book", id: 2 },
  { title: "profile.edit", icon: "fluent:edit-28-regular", id: 3 },
  { title: "profile.archieve", icon: "fluent:archive-16-regular", id: 4 },
];

const outerDropDownMenu = [
  { title: "profile.addBookie", icon: "f7:plus-app", id: 1 },
  { title: "profile.edit", icon: "fluent:edit-28-regular", id: 2 },
  { title: "profile.archieve", icon: "fluent:archive-16-regular", id: 3 },
];

const HiddenAboveLgCol = styled.div`
  @media (min-width: 1199px) {
    display: none;
  }
`;
const HiddenBelowLgDiv = styled.div`
  @media (max-width: 1199px) {
    display: none;
  }
`;
const HiddenAboveLgDiv = styled.div`
  @media (min-width: 1199px) {
    display: none;
  }
`;

const DropdownMenu = styled.div`
  background-color: ${theme.colors.grey.dark};
  border-radius: 8px;
  overflow: hidden;
  min-width: 197px;
  box-shadow: 0px 0px 3px 0px rgba(255, 255, 255, 0.5);
`;

const CustomDiv = styled.div`
  width: 14.4%;
  @media (max-width: 1200px) {
    width: 33.4%;
  }
`;

const CustomProfitDiv = styled.div`
  width: 15.5%;
  @media (max-width: 1200px) {
    width: 50%;
  }
`;
const CustomDivHide = styled.div`
  width: 14.4%;
  @media (max-width: 1200px) {
    display: none;
  }
`;
const AccordianProfile = ({
  elem,
  index,
  handleDropdownMenuClick,
  handleArchieveActivateProfile,
  handleBookieDropdownMenuClick,

}: any) => {
  const { convertToAppCurrency } = useAuth();
  const { refetch } = useGetProfiles({});
  const { t } = useTranslation();
  const profileErrorCount = (elem as Profile)?.bookies?.filter(
    (item) => item?.error
  ).length;
  const [activeKey, setActiveKey] = React.useState<any>(null);
  const { setProfileIdSelected, setProfileCountry, setProfileBookiesSelected, setProfileNameSelected } = useContext(TabContext);
  const navigate = useNavigate();

  const [openModal, setOpenModal] = React.useState(false);

  const { availableCredits } = usePayment();

  const handleToggle = (key: number, profileId: string, country: string,  bookies: Array<string>, name: string) => {
    setActiveKey(activeKey === key ? null : key);
    setProfileIdSelected(profileId);
    setProfileCountry(country);
    setProfileBookiesSelected(bookies);
    setProfileNameSelected(name);
  };

  const { mutate: updateBookie } = useUpdateBookies({
    onSuccess: (data) => {
      refetch();
    },
    onError: (error) => {
      toast.error(t("errors.updateBookie"));
    },
  });

  const handleArchieveBookie = (bookies) => {
    const updateData: UpdateBookiesParams = {
      bookieId: bookies?.accountId,
      isEnabled: true,
      bookie: bookies?.bookie,
    };
    updateBookie(updateData, {
      onSuccess: () => {
        toast.success(t("success.bookieResumed"));
      },
    });
  };

  return (
    <>
      {elem?.isActive === true ? (
        <Accordion
          activeKey={activeKey}
          style={{ marginBottom: "18px", marginTop: "12px" }}
        >
          <div
            style={{
              backgroundColor: theme.colors.grey.dark,
              borderRadius: "8px",
              padding: "10px 16px 10px 24px",
              cursor: "pointer",
            }}
            onClick={() => handleToggle(index, elem?.profileId, elem?.country, elem?.bookies, elem?.profileName)}
          >
            <div className="d-flex align-items-center">
              <CustomDiv>
                <div className="d-flex align-items-center">
                  <Icon
                    icon={activeKey === index ? "fe:arrow-up" : "fe:arrow-down"}
                    width="24"
                    className="me-3"
                    style={{ color: theme.colors.white.normal, minWidth:'24px' }}
                  />
                  <div className="d-flex align-items-center">
                    <div>
                    <img
                      src={`${flags[elem?.country?.toLowerCase()]}`}
                      width={24}
                      className="me-2"
                      alt="flag"
                    />
                    </div>
                    <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width:'auto',
                      minWidth: 0,
                      maxWidth: '120px',
                    }}
                    >
                    <Heading
                      title={elem?.profileName}
                      fontSize="14px"
                      fontWeight="700"
                      color={theme.colors.white.normal}
                      style={{
                       overflow: "hidden",
                       textOverflow: "ellipsis",
                      }}
                    />
                    </div>

                    {profileErrorCount > 0 && (
                      <Badge
                        count={profileErrorCount}
                        style={{ marginLeft: "8px" }}
                      />
                    )}
                  </div>
                </div>
              </CustomDiv>
              <CustomProfitDiv style={{ textAlign: "right" }}>
                <Heading
                  title={convertToAppCurrency(elem.profits)}
                  fontSize="14px"
                  fontWeight="700"
                  color={theme.colors.white.normal}
                />
              </CustomProfitDiv>
              <CustomDivHide />
              <CustomDivHide />
              <CustomDivHide />
              <CustomDivHide />
              {openModal && <AddCreditsModal 
              open={openModal} 
              onClose={()=> setOpenModal(false)} 
              actionBtnTitle={'profile.creditsNeededAction'}
              onActionClick={()=> navigate('/payments')}
              />}
              <CustomDiv style={{ textAlign: "right" }}>
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <DropdownMenu>
                      {outerDropDownMenu?.map((item, index) => (
                        <HoverDiv
                          key={index}
                          onClick={(e) => {
                            if(item.id === 1 && availableCredits < 1){
                              e.stopPropagation();
                              setOpenModal(true);
                            }else{
                            e.stopPropagation();
                            handleDropdownMenuClick(item?.id);
                            }
                          }}
                        >
                          <Icon
                            icon={item?.icon}
                            width="16"
                            height="16"
                            style={{
                              color: theme.colors.white.normal,
                              marginRight: "6px",
                            }}
                          />
                          <Heading
                            title={item?.title}
                            fontSize="14px"
                            fontWeight="400"
                            color={theme.colors.white.normal}
                          />
                        </HoverDiv>
                      ))}
                    </DropdownMenu>
                  }
                >
                  <IconButtonHover
                    onClick={(e) => {
                      e.stopPropagation();
                      setProfileIdSelected(elem?.profileId);
                      setProfileCountry(elem?.country);
                      setProfileBookiesSelected(elem?.bookies);
                      setProfileNameSelected(elem?.profileName);
                    }}
                    src={verticalDots}
                    alt="verticalDots"
                  />
                </Dropdown>
              </CustomDiv>
            </div>
          </div>
          <Accordion.Collapse eventKey={index}>
            <div className="mb-3">
              {elem?.bookies?.length === 0 ? (
                <div style={{ height: "100%", marginBottom: "24px" }}>
                  <Heading
                    title="profile.thereAreNoBookiesDesk"
                    fontSize="14px"
                    fontWeight="400"
                    style={{ margin: "16px 24px" }}
                    color={theme.colors.white.normal}
                  />
                </div>
              ) : (
                elem?.bookies
                  ?.sort((a, b) =>
                    a.isEnabled === b.isEnabled ? 0 : a.isEnabled ? -1 : 1
                  )
                  .map((subElem: any, i: any) => {
                    return (
                      <div
                        key={i}
                        style={{
                          borderBottom: `0.5px solid ${theme.colors.grey.dark}`,
                        }}
                      >
                        {subElem?.isEnabled !== false ? (
                          <>
                            <div
                              className="d-flex align-items-center"
                              style={{ padding: "10px 16px 10px 24px" }}
                            >
                              <CustomDiv>
                                <div className="d-flex align-items-center">
                                <div>
                                  <img
                                    src={require(`../../assets/logosBookies/${subElem?.bookie.toLowerCase()}.png`)}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50px",
                                      marginRight: "10px",
                                    }}
                                    alt="bookie"
                                  />
                                  </div>
                                  <div
                                   style={{
                                     whiteSpace: "nowrap",
                                     overflow: "hidden",
                                     textOverflow: "ellipsis",
                                     width:'120px',
                                     minWidth: 0,
                                   }}
                                  >
                                    <Heading
                                      title={subElem?.username}
                                      fontSize="14px"
                                      fontWeight="700"
                                      color={theme.colors.white.normal}
                                      style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                      }}
                                    />
                                    <HiddenBelowLgDiv>
                                      <Heading
                                        title={`Credit limit: ${subElem?.creditLimit}`}
                                        fontSize="12px"
                                        fontWeight="400"
                                        color={theme.colors.white.dark}
                                      />
                                    </HiddenBelowLgDiv>
                                    <HiddenAboveLgDiv>
                                      <Chip
                                        title={
                                          subElem?.error
                                            ? t("profile.error")
                                            : !subElem?.slot
                                            ?  t("profile.pause")
                                            : subElem?.isRunning
                                            ? t("profile.active")
                                            : t("profile.waiting") 
                                        }
                                        color={
                                          subElem?.error
                                            ? theme.colors.red.normal
                                            : !subElem?.slot
                                            ? theme.colors.gray
                                            : subElem?.isRunning
                                            ? theme.colors.green.normal
                                            : theme.colors.yellow.normal
                                        }
                                        bgColor={theme.colors.grey.dark}
                                      />
                                    </HiddenAboveLgDiv>
                                  </div>
                                </div>
                              </CustomDiv>
                              <CustomProfitDiv style={{ textAlign: "right" }}>
                                <Heading
                                  title={convertToAppCurrency(
                                    subElem?.profits || 0
                                  )}
                                  fontSize="14px"
                                  fontWeight="700"
                                  color={theme.colors.white.normal}
                                />
                              </CustomProfitDiv>
                              <CustomDivHide style={{ textAlign: "right" }}>
                                <Chip
                                  title={
                                    subElem?.error
                                      ? t("profile.error")
                                      : !subElem?.slot
                                      ? t("profile.pause")
                                      : subElem?.isRunning
                                      ? t("profile.active")
                                      : t("profile.waiting")
                                  }
                                  color={
                                    subElem?.error
                                      ? theme.colors.red.normal
                                      : !subElem?.slot
                                      ? theme.colors.gray
                                      : subElem?.isRunning
                                      ? theme.colors.green.normal
                                      : theme.colors.yellow.normal
                                  }
                                  bgColor={theme.colors.grey.dark}
                                />
                              </CustomDivHide>
                              <CustomDivHide style={{ textAlign: "right" }}>
                                <Heading
                                  title={convertToAppCurrency(
                                    subElem?.last_bank || 0
                                  )}
                                  fontSize="14px"
                                  fontWeight="700"
                                  color={theme.colors.white.normal}
                                />
                              </CustomDivHide>
                              <CustomDivHide style={{ textAlign: "right" }}>
                                <Heading
                                  title={convertToAppCurrency(
                                    subElem?.total_bank || 0
                                  )}
                                  fontSize="14px"
                                  fontWeight="700"
                                  color={theme.colors.white.normal}
                                />
                              </CustomDivHide>
                              <CustomDivHide style={{ textAlign: "right" }}>
                                <Heading
                                  title={subElem?.currentOB_number}
                                  fontSize="14px"
                                  fontWeight="700"
                                  color={theme.colors.white.normal}
                                />
                              </CustomDivHide>
                              <CustomDiv style={{ textAlign: "right" }}>
                                <Dropdown
                                  trigger={["click"]}
                                  overlay={
                                    <DropdownMenu>
                                      {dropDownMenu?.map((item, index) => {
                                        return (
                                        <HoverDiv
                                          key={index}
                                          onClick={() =>
                                            handleBookieDropdownMenuClick(
                                              item?.id,
                                              subElem
                                            )
                                          }
                                        >
                                          {item?.id !== 1 && (
                                            <Icon
                                              icon={item?.icon}
                                              width="16"
                                              height="16"
                                              style={{
                                                color:
                                                  theme.colors.white.normal,
                                                marginRight: "6px",
                                              }}
                                            />
                                          )}
                                          {item?.id === 1 && (
                                            <Icon
                                              width="16"
                                              height="16"
                                              style={{
                                                color:
                                                  theme.colors.white.normal,
                                                marginRight: "6px",
                                              }}
                                              icon={
                                                subElem?.isRunning
                                                  ? "heroicons-outline:pause"
                                                  : "icon-park-outline:play"
                                              }
                                            />
                                          )}
                                          {item?.id === 1 ? (
                                            <Heading
                                              title={
                                                subElem?.isRunning
                                                  ? "profile.pause"
                                                  : "profile.play"
                                              }
                                              fontSize="14px"
                                              fontWeight="400"
                                              color={theme.colors.white.normal}
                                            />
                                          ) : (
                                            <Heading
                                              title={item?.title}
                                              fontSize="14px"
                                              fontWeight="400"
                                              color={theme.colors.white.normal}
                                            />
                                          )}
                                        </HoverDiv>
                                      )})}
                                    </DropdownMenu>
                                  }
                                >
                                  <IconButtonHover
                                    src={verticalDots}
                                    alt="verticalDots"
                                    style={{}}
                                  />
                                </Dropdown>
                              </CustomDiv>
                            </div>
                            <div>
                              <HiddenAboveLgCol
                                style={{ padding: "10px 16px 10px 72px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "4px",
                                    }}
                                  >
                                    <Heading
                                      title="profile.bank"
                                      fontSize="14px"
                                      fontWeight="400"
                                      color={theme.colors.white.normal}
                                      style={{ minWidth: "150px" }}
                                    />
                                    <Heading
                                      title={convertToAppCurrency(subElem?.last_bank)}
                                      fontSize="14px"
                                      fontWeight="400"
                                      color={theme.colors.white.normal}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "4px",
                                    }}
                                  >
                                    <Heading
                                      title="profile.totalBank"
                                      fontSize="14px"
                                      fontWeight="400"
                                      color={theme.colors.white.normal}
                                      style={{ minWidth: "150px" }}
                                    />
                                    <Heading
                                      title={convertToAppCurrency(subElem?.total_bank)}
                                      fontSize="14px"
                                      fontWeight="400"
                                      color={theme.colors.white.normal}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginBottom: "4px",
                                    }}
                                  >
                                    <Heading
                                      title="profile.openBets"
                                      fontSize="14px"
                                      fontWeight="400"
                                      color={theme.colors.white.normal}
                                      style={{ minWidth: "150px" }}
                                    />
                                    <Heading
                                      title={subElem?.currentOB_number}
                                      fontSize="14px"
                                      fontWeight="400"
                                      color={theme.colors.white.normal}
                                    />
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <Heading
                                      title="profile.creditLimit"
                                      fontSize="14px"
                                      fontWeight="400"
                                      color={theme.colors.white.normal}
                                      style={{ minWidth: "150px" }}
                                    />
                                    <Heading
                                      title={subElem?.creditLimit}
                                      fontSize="14px"
                                      fontWeight="400"
                                      color={theme.colors.white.normal}
                                    />
                                  </div>
                                </div>
                              </HiddenAboveLgCol>
                            </div>

                            {subElem?.error && (
                              <div
                                style={{
                                  paddingBottom: "16px",
                                  paddingLeft: "24px",
                                }}
                              >
                                <Heading
                                  title={subElem?.errorMessage}
                                  fontSize="12px"
                                  fontWeight="400"
                                  color={theme.colors.red.normal}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                          style={{
                            backgroundColor: theme.colors.grey.dark,
                            borderRadius: "8px",
                            padding: "10px 16px 10px 24px",
                            cursor: "pointer",
                            margin: "18.5px 0",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <CustomDiv>
                              <div className="d-flex align-items-center">
                                   <img
                                  alt="No bookie logo"
                                  src={require(`../../assets/logosBookies/${subElem?.bookie.toLowerCase()}.png`)}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50px",
                                    marginRight: "10px",
                                  }}
                                  />
                                <div className="d-flex align-items-center">
                                  <div
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width:'120px',
                                    minWidth: 0,
                                  }}
                                  >
                                    <Heading
                                    title={subElem?.username}
                                    fontSize="14px"
                                    fontWeight="700"
                                    color={theme.colors.white.dark}
                                    />
                                  </div>
                                    <div style={{width:'24px'}} />
                              </div>
                              </div>
                            </CustomDiv>
                            <CustomProfitDiv style={{ textAlign: "right" }}>
                              <Heading
                                title={convertToAppCurrency(
                                  subElem?.profits || 0
                                )}
                                fontSize="14px"
                                fontWeight="700"
                                color={theme.colors.white.dark}
                              />
                            </CustomProfitDiv>
                            <CustomDivHide />
                            <CustomDivHide />
                            <CustomDivHide />
                            <CustomDivHide />
                            <CustomDiv style={{ textAlign: "right" }}>
                                <IconButtonHover
                                  onClick={() => handleArchieveBookie(subElem)}
                                  src={Reload}
                                  alt="Reload"
                                />
                            </CustomDiv>
                          </div>
                        </div>
                        )}
                      </div>
                    );
                  })
              )}
            </div>
          </Accordion.Collapse>
        </Accordion>
      ) : (
        <div style={{ marginBottom: "18px", marginTop: "12px" }}>
        <div
          style={{
            backgroundColor: theme.colors.grey.normal,
            borderRadius: "8px",
            padding: "10px 16px 10px 24px",
          }}
        >
          <div className="d-flex align-items-center">
            <CustomDiv>
              <div className="d-flex align-items-center">
              <div style={{ width: "24px", height: "24px", marginRight:'1rem' }}/>
                <div className="d-flex align-items-center">
                  <div>
                  <img
                    src={`${flags[elem?.country?.toLowerCase()]}`}
                    width={24}
                    className="me-2"
                    style={{ filter: "grayscale(100%)" }}
                    alt="flag"
                  />
                 </div>
                    <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width:'120px'
                    }}
                    >
                    <Heading
                      title={elem?.profileName}
                      fontSize="14px"
                      fontWeight="700"
                      color={theme.colors.white.normal}
                      style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                      }}
                    />
                    </div>
                </div>
              </div>
            </CustomDiv>
    
            <CustomProfitDiv style={{ textAlign: "right" }}>
              <Heading
                title={convertToAppCurrency(elem.profits)}
                fontSize="14px"
                fontWeight="700"
                color={theme.colors.white.dark}
              />
            </CustomProfitDiv>
    
            <CustomDivHide />
            <CustomDivHide />
            <CustomDivHide />
            <CustomDivHide />
  
            <CustomDiv style={{ textAlign: "right" }}>
              <IconButtonHover
                src={Reload}
                alt="reload"
                onClick={() => handleArchieveActivateProfile(elem?.profileId)}
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              />
            </CustomDiv>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default AccordianProfile;
