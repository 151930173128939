// libraries
import { AxiosError } from "axios";
import { toast } from "react-toastify";

// misc
import { useTranslation } from "react-i18next";
import { ServerResponse } from "@api/types";
import { useAuth } from "@context/AuthContext";
import { useState } from "react";

type ErrorHandlerType = {
  title?: string;
  error: AxiosError<ServerResponse<unknown>>;
  callback?: () => void;
};

export const useErrorHandler = () => {
  // variables
  const { t } = useTranslation();
  const { resetAuth } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);

  // function
  const triggerError = async ({ title, error, callback }: ErrorHandlerType | any) => {
    while (isProcessing) {
      await new Promise(resolve => setTimeout(resolve, 100)); // wait until processing is done
    }

    setIsProcessing(true);
    try {
      if (error.code === "ERR_NETWORK" && !!localStorage.getItem("APP_AUTH_TOKEN")) {
        resetAuth();
        // toast.error(t("errors.expiredToken"));
      } else if (error.response?.status === 401 && !!localStorage.getItem("APP_AUTH_TOKEN")) {
        resetAuth();
        toast.error(t("errors.expiredToken"));
      } else if (error.message && error.response?.status === 404) {
        toast.error(error.response?.data?.message);
      } else if (error.response?.status === 409) {
        toast.error(error.response?.data?.error);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return { triggerError };
};
